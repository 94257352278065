<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Agosto 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Filtros para buscar bodegas -->
        <section class="d-flex">
            <!-- Filtrar por el código de una bodega -->
            <v-text-field class="me-2 filtro" v-model="buscarCodigo" label="Código" outlined dense hide-details
                @input="listarBodegas()" @keypress="soloNumeros">
            </v-text-field>
            <!-- Filtrar por el nombre de una bodega -->
            <v-text-field class="me-2 filtro" v-model="buscarNombre" label="Nombre" outlined dense hide-details
                @input="listarBodegas()">
            </v-text-field>
            <!-- Filtrar por el punto de venta de una bodega -->
            <v-text-field class="me-2 filtro" v-model="buscarPunto" label="Punto de venta" outlined dense hide-details
                @input="listarBodegas()" @keypress="soloNumeros">
            </v-text-field>
            <!-- Este botón abre un diálogo para agregar una bodega -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on"
                            @mousedown.prevent="dialogoCrearBodega = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear una bodega -->
        <v-dialog v-model="dialogoCrearBodega" transition="dialog-bottom-transition" max-width="35rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">Crear bodega</span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para registrar los datos de una bodega -->
                        <v-form lazy-validation>
                            <!-- fila 1 -->
                            <div class="d-flex filas">
                                <!-- Campo para ingresar el código de la bodega -->
                                <validation-provider v-slot="{ errors }" ref="codigoValidation" :rules="{ required: true }" class="me-4 campos">
                                    <v-text-field v-model="crearBodega.codigo" label="Código" dense outlined
                                        :error-messages="validarBodega || errors" return="codigo" @keypress="primerNumero">
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo que almacena el punto de venta -->
                                <validation-provider v-slot="{ errors }" ref="puntoVentaValidation" :rules="{ required: true }" class="campos">
                                    <v-text-field class="campo-con-error" v-model="crearBodega.puntoVenta" label="Punto de venta" dense outlined
                                        :error-messages="validarPunto || errors" return="puntoVenta" :disabled="disabledPunto" :readonly="readonlyPunto">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <!-- fila 2 -->
                            <div class="d-flex filas">
                                <!-- Campo para ingresar el número de contacto -->
                                <validation-provider v-slot="{ errors }" ref="contactoValidation"
                                    :rules="{ required: true, numeric: true, min: 7, max: 12 }" class="me-4 campos">
                                    <v-text-field class="campo-con-error" v-model="crearBodega.contacto" label="Número de contacto" dense outlined
                                        :error-messages="errors" return="contacto" :disabled="disabledContacto" :readonly="readonlyContacto">
                                    </v-text-field>
                                </validation-provider>

                                <!-- Campo que almacena el NIT -->
                                <validation-provider v-slot="{ errors }" ref="documentoValidation" :rules="{ required: true }" class="campos">
                                    <v-text-field v-model="crearBodega.documento" label="NIT" dense outlined disabled
                                        :error-messages="errors" return="documento">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <!-- fila 3 -->
                            <div class="d-flex filas">
                                <!-- Campo que almacena el nombre de la bodega -->
                                <validation-provider v-slot="{ errors }" ref="nombreValidation" :rules="{ required: true }" class="campos">
                                    <v-text-field v-model="crearBodega.nombre" label="Nombre" dense outlined disabled
                                        :error-messages="errors" return="nombre">
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <!-- fila 4 -->
                            <div class="d-flex filas">
                                <!-- Campo para ingresar la dirección de la bodega -->
                                <validation-provider v-slot="{ errors }" ref="direccionValidation" :rules="{ required: true }" class="campos">
                                    <v-text-field class="campo-con-error" v-model="crearBodega.direccion" label="Dirección" dense outlined
                                        :error-messages="errorDireccion || errors" return="direccion" :disabled="disabledDireccion" :readonly="readonlyDireccion">
                                    </v-text-field>
                                </validation-provider>
                            </div>

                            <div><span class="error-general">{{ errorGeneral }}</span></div>

                            <!-- Botones -->
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonDisabled" text depressed
                                    @click="agregarBodega()">Guardar</v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla de las bodegas cradas -->
        <v-data-table class="elevation mt-4" :items="tablaBodegas.items" fixed-header :headers="tablaBodegas.headers"
            :loading="tablaBodegas.loading" :page.sync="tablaBodegas.page" :server-items-length="tablaBodegas.totalPage"
            :footer-props="tablaBodegas.footerProps" :items-per-page.sync="tablaBodegas.itemsPerPage" height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.codigoBodega">
                        <td> {{ item.bodegasId.codigoBodega }}</td>
                        <td> {{ item.nombreBodega }}</td>
                        <td> {{ item.direccion }}</td>
                        <td> {{ item.numeroContacto }}</td>
                        <td> {{ item.numeroDocumento }}</td>
                        <td class="text-center"> {{ item.puntoVenta.puntoVentaId.codigoPuntoVenta }}</td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import { required, numeric, min, max } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.'
});
extend('numeric', {
    ...numeric,
    message: 'Este campo solo permite números.'
});
extend('min', {
    ...min,
    message: 'Este campo permite mínimo 7 dígitos.'
})
extend('max', {
    ...max,
    message: 'Este campo permite máximo 12 dígitos.'
})
// validaciones con vee-validate---fin
/**
 * Crea y devuelve una versión "debounceada" de la función proporcionada.
 * La función "debounceada" retrasa la invocación de la función original 
 * hasta después de que hayan transcurrido 'delay' milisegundos desde
 * la última vez que la función debounceada fue invocada.
 * 
 * @param {Function} fn - La función original que deseas "debouncear".
 * @param {number} delay - El número de milisegundos a esperar antes de ejecutar 'fn'.
 * @return {Function} Una nueva función que no se ejecuta inmediatamente, sino después del 'delay' especificado.
 */
const debounce = function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};
export default {
    name: "Bodegas",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            buscarCodigo: '',
            buscarNombre: '',
            buscarPunto: '',
            dialogoCrearBodega: false,
            crearBodega: {
                codigo: '',
                nombre: '',
                direccion: '',
                documento: '',
                puntoVenta: '',
                contacto: '',
                codigoCentroCostos: '',
            },
            validarBodega: '',
            validarPunto: '',
            errorDireccion: '',
            lastmodifiedby: '',
            tablaBodegas: {
                loading: false,
                items: [],
                headers: [
                    { text: "CÓDIGO", width: "8%", sortable: false },
                    { text: "NOMBRE", width: "30%", sortable: false },
                    { text: "DIRECCIÓN", width: "30%", sortable: false },
                    { text: "NÚMERO DE CONTACTO", width: "11%", sortable: false },
                    { text: "NIT", width: "11%", sortable: false },
                    { text: "PUNTO DE VENTA", align: "center", width: "10%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            userRoles: {},
            roles: Role,
            botonDisabled: false,
            errorGeneral: '',
            disabledContacto: true,
            disabledDireccion: true,
            disabledPunto: true,
            readonlyContacto: false,
            readonlyDireccion: false,
            readonlyPunto: false,
            existe: false,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        // Escucha los cambios en la página actual de la tabla de bodegas.
        // Si cambia, llama a la función para listar las bodegas.
        'tablaBodegas.page': function () {
            this.listarBodegas();
        },
        // Escucha cambios en la cantidad de ítems por página en la tabla de bodegas.
        // Si cambia, reinicia la página actual a 1 y lista las bodegas.
        'tablaBodegas.itemsPerPage': function () {
            this.tablaBodegas.page = 1;
            this.listarBodegas();
        },
        // Escucha cambios en los filtros de búsqueda.
        // Si el criterio de búsqueda cambia, reinicia la página actual a 1.
        'buscarCodigo': function () {
            this.tablaBodegas.page = 1;
        },
        'buscarNombre': function () {
            this.tablaBodegas.page = 1;
        },
        'buscarPunto': function () {
            this.tablaBodegas.page = 1;
        },
        // Escucha cambios en el campo código del formulario.
        // Si cambia, se "debouncea" la validación de la bodega, 
        // asegurando que la función 'validarExistenciaStone' solo se 
        // ejecute una vez después de un retardo de 1.5 segundos.
        'crearBodega.codigo': debounce(function () {
            this.validarExistenciaStone();
        }, 1500)
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         * Método que obtiene y lista las bodegas de la base de datos utilizando criterios de paginación y filtrado.
         * La respuesta obtenida se utiliza para actualizar la tabla de bodegas en el frontend,
         * asignando la lista de bodegas a 'tablaBodegas.items' y la cantidad total de bodegas a 'tablaBodegas.totalPage'.
         */
        listarBodegas() {
            this.tablaBodegas.loading = true;
            this.$http
                .get(`msa-administration/bodegas/listarBodegas`, {
                    params: {
                        page: this.tablaBodegas.page - 1,
                        size: this.tablaBodegas.itemsPerPage,
                        codigoBodega: `${this.buscarCodigo}`,
                        nombre: `${this.buscarNombre.toLowerCase()}`,
                        puntoVenta: `${this.buscarPunto}`,
                        idEmpresa: this.enterprise.code
                    }
                }).then(response => {
                    this.tablaBodegas.items = response.data.content;
                    this.tablaBodegas.totalPage = response.data.totalElements;
                    this.tablaBodegas.loading = false;
                }).catch(error => {
                    console.log(error);
                    this.tablaBodegas.loading = false;
                });
        },
        primerNumero(event) {
            const key = event.key;
            const inputField = event.target;
            if (inputField.value.length === 0 && key === '0') {
                event.preventDefault();
                return;
            }
            this.soloNumeros(event);
        },
        /**
         * Método que solo permite el ingreso de números
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * lo que hace que no se ingrese el valor de la tecla
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada.
         */
        soloNumeros(event) {
            const key = event.key;
            if (!key.match(/^[0-9]+$/)) {
                event.preventDefault();
            }
        },
        /**
         * Método que reinicia los valores del formulario de bodega y resetea las validaciones(mensajes de error).
         */
        limpiarFormulario() {
            this.crearBodega.nombre = '';
            this.crearBodega.direccion = '';
            this.crearBodega.documento = '';
            this.crearBodega.puntoVenta = '';
            this.crearBodega.contacto = '';
            this.$refs.observer.reset();
            this.errorGeneral = '';
            this.validarPunto = '';
        },
        /**
         * Método que valida si una bodega existe en Stone basándose en el código ingresado.
         * Si la bodega no existe en Stone, limpia el formulario. Si existe, procede a validar si la bodega 
         * ya existe en la base de datos local.
         */
        validarExistenciaStone() {
            this.validarBodega = '';
            const codigo = this.crearBodega.codigo;
            if (codigo && codigo != 0 && codigo <= 32767) {
                this.$http
                    .get(`msa-administration/inBodeg/validarExistencia` , {
                        params: {
                            codBode: codigo,
                            codEmpr: this.enterprise.code,
                            actEsta: 'B'
                        }
                    })
                    .then((response) => {
                        if (response.data !== null) {
                            this.validarExistenciaPostgres();
                            this.validarBodega = '';
                        } else {
                            this.validarBodega = 'La bodega no existe en Stone';
                            this.limpiarFormulario();
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
            } else if (codigo >= 32768) {
                this.validarBodega = 'La bodega no existe en Stone';
            } else {
                this.limpiarFormulario();
                this.validarBodega = '';
                this.validarPunto = '';
                this.errorDireccion = '';
                this.errorGeneral = '';
                this.disabledContacto = true;
                this.disabledDireccion = true;
                this.disabledPunto = true;
                return;
            }
        },
        /**
         * Método que valida si una bodega ya existe en Postgres.
         * Si la respuesta indica que la bodega ya existe, deshabilita el botón de guardar y 
         * muestra un mensaje de error. Si la bodega no existe, procede a recuperar la información 
         * de la bodega desde Stone y habilita el botón de guardar.
         */
        validarExistenciaPostgres() {
            const codigo = this.crearBodega.codigo;
            this.$http
                .get(`msa-administration/bodegas/existeBodega`, {
                    params: {
                        codigoBodega: codigo,
                        idEmpresa: this.enterprise.code
                    }
                }).then((response) => {
                    if (response.data !== null) {
                        this.validarBodega = 'La bodega ya existe.';
                        this.botonDisabled = true;
                        this.existe = true;
                    } else {
                        this.botonDisabled = false;
                        this.validarBodega = '';
                    }
                    this.traerBodegaStone();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Si se encuentra la bodega de Stone, actualiza el formulario con la información recuperada.
         * También valida que el número de contacto sea un número válido.
         * Si no se encuentra la bodega, limpia el formulario.
         */
        traerBodegaStone() {
            const codigo = this.crearBodega.codigo;
            this.$http
                .get(`msa-administration/inBodeg/traerBodega`, {
                    params: {
                        codigo: codigo,
                        codigoEmpresa: this.enterprise.code,
                        estado : 'B'
                    }
                }).then((response) => {
                    if (response.data && response.data.length === 1) {
                        const bodega = response.data[0];
                        this.crearBodega.nombre = bodega.nombre.trimEnd();
                        this.crearBodega.direccion = bodega.direccion.trimEnd();
                        this.crearBodega.documento = bodega.documento;
                        this.crearBodega.puntoVenta = `${bodega.codigoPuntoVenta} - ${bodega.nombrePuntoVenta}`;
                        this.crearBodega.contacto = bodega.contacto.replace(/\s+/g, '').trim();
                        this.crearBodega.codigoCentroCostos = bodega.codigoCentroCostos;
                        if (!this.existe) {
                            this.validarContacto();
                            this.validarDireccion();
                            this.validarPuntoDeVenta();
                        }
                    } else {
                        this.limpiarFormulario();
                    }
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Valida el campo de contacto. Si el contacto no cumple con el formato numérico,
         * se establece un mensaje de error y se requiere modificación en Stone.
         */
        validarContacto() {
            const numero = /^[0-9]+$/;
            if (!numero.test(this.crearBodega.contacto)) {
                this.$nextTick(() => {
                    this.$refs.contactoValidation.validate();
                });
                this.disabledContacto = false;
                this.readonlyContacto = true;
                this.errorGeneral = 'Para crear la bodega se debe modificar la información desde Stone.';
            } else {
                this.errorGeneral = '';
                this.disabledContacto = true;
                this.readonlyContacto = false;
            }
        },
        /**
         * Valida el campo de dirección. Si la dirección inicia con un punto, 
         * se establece un mensaje de error y se requiere modificación en Stone.
         */
        validarDireccion() {
            if (this.crearBodega.direccion.startsWith('.')) {
                this.errorDireccion = 'Ingrese una dirección válida';
                this.errorGeneral = 'Para crear la bodega se debe modificar la información desde Stone.';
                this.disabledDireccion = false;
                this.readonlyDireccion = true;
            } else {
                this.errorDireccion = '';
                this.disabledDireccion = true;
                this.readonlyDireccion = false;
            }
        },
        validarPuntoDeVenta() {
            this.validarPunto = '';
            const puntoVenta = this.crearBodega.puntoVenta;
            const codigoVenta = puntoVenta.split(' - ')[0].trim();
            this.$http.get(`msa-administration/puntoVenta/buscarId`, {
                params: {
                    codigoEmpresa: this.enterprise.code,
                    codigoPuntoVenta: codigoVenta
                }
            }).then((response) => {
                if(response.data === null){
                    this.validarPunto = 'El punto de venta no existe.';
                    this.botonDisabled = true;
                    this.disabledPunto = false;
                    this.readonlyPunto = true;
                }
            }).catch((error) => {
                console.log(error);
            })
        },
        /**
         * Método que crea una nueva bodega en la base de datos Postgres.
         * Construye un objeto `bodega` con los valores actuales del formulario.
         * El punto de venta (puntoVenta) se divide para extraer el código de venta (codigoVenta) y 
         * se incluye en el objeto `bodega`.
         * Una vez construido el objeto, realiza una solicitud para crear la bodega en la base de datos.
         * Finalmente actualiza la tabla, cierra el diálogo de creación y limpia el formulario.
         */
        agregarBodega() {
            const puntoVenta = this.crearBodega.puntoVenta;
            const codigoVenta = puntoVenta.split(' - ')[0].trim();
            const bodega = {
                bodegasId: {
                    idEmpresa: this.enterprise.code,
                    codigoBodega: this.crearBodega.codigo
                },
                nombreBodega: this.crearBodega.nombre,
                numeroDocumento: this.crearBodega.documento,
                puntoVentaId: {
                    idEmpresa: this.enterprise.code,
                    codigoPuntoVenta: codigoVenta
                },
                direccion: this.crearBodega.direccion,
                numeroContacto: this.crearBodega.contacto,
                codigoCentroCostos: this.crearBodega.codigoCentroCostos,
                lastmodifiedby: this.lastmodifiedby
            };
            this.$http
                .post("msa-administration/bodegas/guardar", bodega)
                .then(() => {
                    this.listarBodegas();
                    this.dialogoCrearBodega = false;
                    this.clear();
                }).catch(error => {
                    console.log(error);
                });
        },
        /**
         * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
         */
        clear() {
            this.dialogoCrearBodega = false;
            this.crearBodega.codigo = '';
            this.crearBodega.nombre = '';
            this.crearBodega.direccion = '';
            this.crearBodega.documento = '';
            this.crearBodega.puntoVenta = '';
            this.crearBodega.contacto = '';
            this.botonDisabled = false;
            this.validarBodega = '';
            this.validarPunto = '';
            this.errorDireccion = '';
            this.errorGeneral = '';
            this.disabledContacto = true;
            this.disabledDireccion = true;
            this.disabledPunto = true;
            this.$refs.observer.reset();
        },
    },
    mounted() {
        this.listarBodegas();
        this.lastmodifiedby = this.auth.username.toUpperCase();
    },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    height: 86vh;
}

.filtro {
    width: 20%;
}

.encabezado {
    background-color: #1867c0;
    color: white;
}

.dialogo {
    padding-top: 20px !important;
}

.filas {
    width: 100% !important;
}

.campos {
    width: 100%;
}

::v-deep .elevation .v-data-footer {
    width: 100%;
}

::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}

.error-general{
    color: #FF5252;
    font-size: 12px;
}

::v-deep .campo-con-error .v-text-field__slot input{
    color: #00000061 !important;
}
</style>